<template>
  <v-card flat class="rounded-10" min-height="100vh">
    <v-card-title class="text-center py-5">
      <v-row align="center" justify="center">
        <v-card flat width="200" height="150" v-if="mdAndUp">
          <v-img
            :lazy-src="settings.loader"
            height="100"
            contain
            :src="settings.squareLogo"
          ></v-img>
          <v-card-actions class="d-flex">
            <h2
              class="fs-18 align-self-center mx-auto primary--text font-weight-bold"
            >
              {{ settings.SiteName }}
            </h2>
          </v-card-actions>
        </v-card>
        <v-icon x-large class="mx-5" color="primary" v-if="mdAndUp">
          mdi-arrow-left-right-bold
        </v-icon>
        <v-card flat width="200" height="150">
          <v-img
            :lazy-src="settings.loader"
            contain
            height="100"
            :src="connectApp.logo"
          ></v-img>
          <v-card-actions class="d-flex">
            <h2
              class="fs-18 align-self-center mx-auto primary--text font-weight-bold"
            >
              {{ connectApp.name }}
            </h2>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense class="mb-1">
        <p class="text-center secondary--text col-md-8 mx-auto fs-20 py-5">
          Integration with {{ connectApp.name }} will have access to following
          functionality:
        </p>
        <ul class="col-md-6 secondary--text mx-auto fs-17">
          <li>
            Send emails and email campaigns using {{ connectApp.name }} via
            {{ settings.SiteName }}
          </li>
          <li>More features coming soon.</li>
        </ul>
      </v-row>
      <v-row dense class="py-3 flex-column" align="center" justify="center">
        <v-img
          :lazy-src="settings.loader"
          height="100"
          contain
          :src="connectApp.longLogo"
        ></v-img>
        <v-btn
          class="text-none"
          @click="
            $router.push({
              name: 'email-settings'
            })
          "
          min-width="200"
          color="primary"
          large
        >
          Go to configuration
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    connectApp: null,
    mailer: null
  }),
  watch: {
    '$route.params': {
      handler: function (val) {
        this.connectApp = this.mailer = null
        if (val.hasOwnProperty('mailer')) {
          this.mailer = val.mailer
          this.connectApp = this.connects.find((i) => i.id === val.mailer)
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters('app', ['connects', 'allowedConnects']),
    available() {
      return this.allowedConnects.includes(this.connectApp.id)
    }
  }
}
</script>

<style lang="scss" scoped></style>
